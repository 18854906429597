const activeLink = document.querySelectorAll(".business-content-tabs ul li ");
for (let clickTab of activeLink) {
  clickTab.onclick = function () {
    let activeClass = document.querySelectorAll("li.active");
    activeClass[0].classList.remove("active");
    clickTab.classList.add("active");
  };
}

// window.addEventListener("scroll", function (e) {
//   document
//     .getElementById("header")
//     .classList[e.pageY > 10 ? "add" : "remove"]("fade-in");
// });

var scrollpos = window.scrollY;
var header = document.getElementById("header");

function add_class_on_scroll() {
  header.classList.add("fade-in");
}

function remove_class_on_scroll() {
  header.classList.remove("fade-in");
}

window.addEventListener("scroll", function () {
  //Here you forgot to update the value
  scrollpos = window.scrollY;
  if (scrollpos > 50) {
    add_class_on_scroll();
  } else {
    remove_class_on_scroll();
  }
});

const searchFocus = document.getElementById('search-focus');
const keys = [
  { keyCode: 'AltLeft', isTriggered: false },
  { keyCode: 'ControlLeft', isTriggered: false },
];

window.addEventListener('keydown', (e) => {
  keys.forEach((obj) => {
    if (obj.keyCode === e.code) {
      obj.isTriggered = true;
    }
  });

  const shortcutTriggered = keys.filter((obj) => obj.isTriggered).length === keys.length;

  if (shortcutTriggered) {
    searchFocus.focus();
  }
});

window.addEventListener('keyup', (e) => {
  keys.forEach((obj) => {
    if (obj.keyCode === e.code) {
      obj.isTriggered = false;
    }
  });
});

function showServices() {
  document.getElementById("headerMobile").style.display = "none";
  document.getElementById("mobileServices").style.display = "block";
}

function closeServices() {
  document.getElementById("headerMobile").style.display = "block";
  document.getElementById("mobileServices").style.display = "none";
}
function showIndustries() {
  document.getElementById("headerMobile").style.display = "none";
  document.getElementById("mobileIndustries").style.display = "block";
}

function closeIndustries() {
  document.getElementById("headerMobile").style.display = "block";
  document.getElementById("mobileIndustries").style.display = "none";
}




